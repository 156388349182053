// export const API_URL = "http://87.107.164.167:3003/";
// export const API_URL = "https://Erptestapi.akafweb.com/";

// export const API_URL_AUTH = "http://87.107.164.167:3003/auth";
// export const API_URL_COMMON = "http://api.nikohamrah.com/api/v1/common";

// test

// export const API_URL = process.env.REACT_APP_BASE_URL;
// export const API_URL_PHOTO = process.env.REACT_APP_BASE_URL_ASSETS;
// export const SITE_URL = "https://almadarsiraj.com";
// export const TEST_CLIENT = process.env.REACT_APP_TEST_URL;
export const API_URL = "https://erptestapi.akafweb.com/";
export const API_URL_PHOTO = "https://assets.almadarsiraj.com/siraj/";
export const SITE_URL = "https://almadarsiraj.com";
export const TEST_CLIENT = process.env.API_URL;
// export const ASSETS_URL = process.env.REACT_APP_BASE_URL_ASSETS;

export const api_key = "AIzaSyALNLX5V6jh9aPuatkrYPBZugIJ8MtRWWA";
export const client_id =
  "386906510333-27gb1o3e9c6mpmcfj3lr9vd5bmlhdt61.apps.googleusercontent.com";

// master

// export const API_URL = "https://api.akafweb.com/";
// export const API_URL_PHOTO = "https://assets.almadarsiraj.com/siraj/";
// export const SITE_URL = "https://almadarsiraj.com";
// export const TEST_CLIENT = "Erp.akafweb.com";
